<template>
  <div style="height: 100vh">
    <Dashboard />
  </div>
</template>

<script>
import Dashboard from "@/components/admin/doctor/dashboard/Dashboard.vue";

export default {
  components: {
    Dashboard,
  },
};
</script>

<style></style>
